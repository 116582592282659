import { ACTION_TYPES } from '@marketmuse/data-state';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USERS_ADMIN = 'GET_USERS_ADMIN';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';

export const SAVE_MEMBER_DATA_TO_STATE = 'SAVE_MEMBER_DATA_TO_STATE';
export const ADD_MEMBER = 'ADD_MEMBER';

export const REQUEST = 'REQUEST';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAIL = 'REQUEST_FAIL';

// site aggregates
export const GET_EXCEL = 'GET_EXCEL';
export const SET_TOOL_STATE = 'SET_TOOL_STATE';
export const GET_QUESTIONS_DATA = 'GET_QUESTIONS_DATA';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const REMOVE_PROJECT_ORDER = 'REMOVE_PROJECT_ORDER';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

export const ADMIN_GET_ORG = 'ADMIN_GET_ORG';

export const UPDATE_ORG_DATA = 'UPDATE_ORG_DATA';
export const UPDATE_USER_DATA = ACTION_TYPES.UPDATE_USER_DATA;

export const DELETE_USER = 'DELETE_USER';
export const GET_APP_DATA = 'GET_APP_DATA';
export const SET_APPS_DATA_START = 'SET_APPS_DATA_START';
export const SET_APPS_DATA_HYDRATING = 'SET_APPS_DATA_HYDRATING';
export const SET_APPS_REDUCER = 'SET_APPS_REDUCER';
export const SAVE_APP_CONFIG_STATE = 'SAVE_APP_CONFIG_STATE';
export const SAVE_APP_DATA = 'SAVE_APP_DATA';
export const INVENTORY_ITEMS_UPDATE = 'INVENTORY_ITEMS_UPDATE';
export const CREATE_INVENTORY_ORG = 'CREATE_INVENTORY_ORG';
export const INVENTORY_UPDATE = 'INVENTORY_UPDATE';
export const GET_INVENTORY_SUBDOMAINS = 'GET_INVENTORY_SUBDOMAINS';
export const INVENTORY_CREATE = 'INVENTORY_CREATE';

export const CLEAR_CACHE_ITEMS = 'CLEAR_CACHE_ITEMS';

export const SET_ACTIVE_FORM_BUILDER = 'SET_ACTIVE_FORM_BUILDER';
export const GET_FORM = 'GET_FORM';
export const GET_FORM_SCHEMA = 'GET_FORM_SCHEMA';
export const GET_FORMS = 'GET_FORMS';
export const UPDATE_FORM = 'UPDATE_FORM';
export const CREATE_FORM = 'CREATE_FORM';
export const DELETE_FORM_BUILDER = 'DELETE_FORM_BUILDER';
export const UPDATE_FORM_GROUP = 'UPDATE_FORM_GROUP';
export const CREATE_FORM_GROUP = 'CREATE_FORM_GROUP';
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const CREATE_FORM_FIELD = 'CREATE_FORM_FIELD';

export const GET_APP_QUERIES = 'GET_APP_QUERIES';
export const GET_SNAPSHOT_DATA = 'GET_SNAPSHOT_DATA';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const INQUIRE_PREMIUM = 'INQUIRE_PREMIUM';
export const RECURLY_GET_ACCOUNT = 'RECURLY_GET_ACCOUNT';

export const RECURLY_UPSERT_BILLING_INFO = 'RECURLY_UPSERT_BILLING_INFO';
export const RECURLY_CREATE_ACCOUNT = 'RECURLY_CREATE_ACCOUNT';
export const RECURLY_VALIDATE_COUPON = 'RECURLY_VALIDATE_COUPON';
export const SIGNUP_PUBLIC = 'SIGNUP_PUBLIC';
export const DELETE_ORG = 'DELETE_ORG';

export const UPSERT_PROJECT = 'UPSERT_PROJECT';
export const UPSERT_PROJECTS = 'UPSERT_PROJECTS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const PROJECT_PLACE_ORDERS = 'PROJECT_PLACE_ORDERS';

export const CREATE_SAVED_VIEW = 'CREATE_SAVED_VIEW';
export const DELETE_SAVED_VIEW = 'DELETE_SAVED_VIEW';
export const GET_SAVED_VIEWS = 'GET_SAVED_VIEWS';
export const GET_SAVED_VIEWS_EACH = 'GET_SAVED_VIEWS_EACH';

export const GET_SITES_BY_ORG = 'GET_SITES_BY_ORG';
