import requestMiddleware from './request';
import site from './site';

// new middlewares

import getExcel from './tools/getExcel';
import deleteOrg from './admin/deleteOrg';

import getAggregateStats from './site/getAggregateStats';
import getSitesByOrg from './site/getSitesByOrg';

import getOrg from './admin/getOrg';
import getUsersAdmin from './admin/getUsersAdmin';
import updateOrgData from './auth/updateOrgData';
import updateUserData from './auth/updateUserData';
import deleteUser from './auth/deleteUser';
import getUserData from './auth/getUserData';
import getAppData from './tools/getAppData';

import setAppsOnboarding from './tools/setAppsOnboarding';
import setAppsHydrating from './tools/setAppsHydrating';
import getInventorySubdomains from './inventory_new/getInventorySubdomains';
import inventoryItemsUpdate from './inventory_new/inventoryItemsUpdate';
import inventoryCreateOrg from './inventory_new/inventoryCreateOrg';
import inventoryCreate from './inventory_new/inventoryCreate';
import inventoryUpdate from './inventory_new/inventoryUpdate';
import addMember from './auth/addMember';
import getAppQueries from './tools/getAppQueries';
import getSnapshotData from './misc/getSnapshotData';
import validateEmail from './misc/validateEmail';
import inquirePremium from './misc/inquirePremium';
import recurlyGetAccount from './recurly/recurlyGetAccount';

import recurlyCreateAccount from './recurly/recurlyCreateAccount';
import recurlyUpsertBillingInfo from './recurly/recurlyUpsertBillingInfo';

import upsertProject from './projects/upsertProject';
import upsertProjects from './projects/upsertProjects';
import getProjects from './projects/getProjects';
import getProject from './projects/getProject';

import removeProjectOrder from './projects/removeProjectOrder';
import projectPlaceOrders from './projects/projectPlaceOrders';

import createSavedView from './savedView/createSavedView';
import getSavedViews from './savedView/getSavedViews';
import deleteSavedView from './savedView/deleteSavedView';
import getSavedViewsEach from './savedView/getSavedViewsEach';

export default () => [
  requestMiddleware(),
  site(),
  getExcel(),
  getOrg(),
  getSitesByOrg(),
  getUsersAdmin(),
  updateOrgData(),
  updateUserData(),
  deleteUser(),
  getUserData(),
  getAppData(),
  setAppsOnboarding(),
  setAppsHydrating(),
  getInventorySubdomains(),
  inventoryItemsUpdate(),
  inventoryCreateOrg(),
  inventoryCreate(),
  inventoryUpdate(),
  getAggregateStats(),
  addMember(),
  deleteOrg(),
  getAppQueries(),
  getSnapshotData(),
  validateEmail(),
  inquirePremium(),
  recurlyCreateAccount(),
  recurlyGetAccount(),
  recurlyUpsertBillingInfo(),
  upsertProject(),
  upsertProjects(),
  getProjects(),
  getProject(),
  removeProjectOrder(),
  projectPlaceOrders(),
  createSavedView(),
  getSavedViews(),
  deleteSavedView(),
  getSavedViewsEach(),
];
