import { persistStore } from 'reduxjs-toolkit-persist';
import localForage from 'localforage';

import { createBrowserHistory as createHistory, type History } from 'history';

import Tracker from '../models/tracker/Tracker';
import enhanceHistory from '../utils/enhanceHistory';
import configureStore from './configureStore';

export const tracker = new Tracker();
export const store = configureStore();
export const persistor = persistStore(store);
export const history: History = enhanceHistory(createHistory());

export const TabsStore = localForage.createInstance({
  name: 'MM_TABS',
  description: 'Key/count pairs for monitoring tab usage',
  version: 1.0,
  driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE],
});
